import Toast from 'react-bootstrap/Toast'
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import _ from "lodash";

export const getBasepath = (path) => {
  let check = path.substring(0, 4);
  let finalPath = process.env.rootPath + path;

  if (check == 'http') {
    return path;
  }

  return finalPath;
}

export const viewArticle = (id, title = '', action = '_self') => {
  if (id <= 0) return;
  let url = getBasepath(`/posts/${id}/` + encodeURIComponent(title.replace(/ /g, '')));
  window.open(url, action);
}

export const copyArticleLink = (articleId, articleTitle) => {
  //let url = process.env.domain + getBasepath(`/posts/${articleId}/` + encodeURIComponent(articleTitle.replace(/ /g, '')));
  // let url = process.env.domain + getBasepath(`/${articleId}`);
  let url = process.env.domain + `/${articleId}`;
  navigator.clipboard.writeText(url);
}

export const viewScripture = (bible) => {
  if (bible) {
    let url = '/bible/' + bible;
    window.open(url, '_blank');
  }
}

export const getUrlParams = () => {
  let path = window.location.pathname;
  let arrayData = _.split(path, '/');
  let dataDecoded = [];
  arrayData.forEach(item => {
    dataDecoded.push(decodeURI(item));
  });
  return dataDecoded;
}

export const detectIos = () => {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let ios = /iphone|ipod|ipad/.test(userAgent);

  if (ios) {
    return true;
  } else {
    return false;
  };
}

export const detectSafari = () => {
  let userAgent = window.navigator.userAgent.toLowerCase();
  let safari = /safari/.test(userAgent);
  let chrome = /chrome/.test(userAgent)

  if (safari && !chrome) {
    return true;
  } else {
    return false;
  };
}