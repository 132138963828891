import React, { useState, useEffect } from 'react';
import styles from '../styles/TopicMenu.module.css';
import Link from 'next/link';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'next/image';
import { commonUrl } from '../pages/api/config';
import * as LIB from '../lib/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const TopicMenu = ({ data, setMenu }) => {

  const closeIcon = <FontAwesomeIcon icon={faTimes} />;

  const closeMenu = () => {
    //release the scollbar
    document.body.style.overflow = "inherit";
    setMenu(false);
  }

  let menuItems = data;
  let menuRows = [];
  let count = 0; // unique element key for each menuRow

  if (menuItems) {    
    for (const catTitle in menuItems) {
      let topicItems = menuItems[catTitle];
      let submenuRows = []; // hold subcat row

      //construct subcat
      for (const topicTitle in topicItems) {
        let topicItem = topicItems[topicTitle];

        // If url provided by API, use it directly, otherwise compose general URL for the corresponding topic ID
        let url = (topicItem.url != null && topicItem.url != '') ? topicItem.url : LIB.getBasepath(`/topics/${topicItem.id}/${topicTitle}`);
        
        submenuRows.push(
          <a href={url} key={topicItem.id}>
            <div className={styles.itemBtn}>{topicTitle}</div>
          </a>
        );
      }

      //construct cat & subcat section
      menuRows.push(
        <Col xl={3} md={4} s={6} xs={6} key={count}>
          <div className={styles.floatLeft}>
            <div className={styles.title}>{catTitle}</div>
            {submenuRows}
          </div>
        </Col>
      );

      count++;
    }

    return (
      <div>
        <div className={[styles.maskLayer]}></div>
        <Container fluid className={styles.innerHolder}>
          <div className={styles.wrapper}>
            <Row>
              <Col>
                <div className={styles.closeBtn} onClick={() => { closeMenu() }}>
                  {closeIcon}
                </div>
              </Col>
            </Row>
            <Row>
              {menuRows}
            </Row>
          </div>
        </Container>
      </div>
    );
  } else {
    return (<></>)
  }

}

export default TopicMenu;

