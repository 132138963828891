
export const basePath = process.env.googleServerlessApi;

export const commonUrl = {
  promotionBars:`${basePath}/zd30_api_promotion_bars`,
  promotion:`${basePath}/zd30_api_news`,
  sundayService:`${basePath}/zd30_api_topic/02c`,
  river:`${basePath}/zd30_api_topic/02a`,
  blog:`${basePath}/zd30_api_topic/04a,04b,04c,04d`,
  music:`${basePath}/zd30_api_topic/01f,01c,01d,01g,01h`,
  bttm:`${basePath}/zd30_api_topic/01g`,
  musicPlaylist:`${basePath}/zd30_api_music_youtube_playlist`,
  video:`${basePath}/zd30_api_latest_video`,
  menu:`${basePath}/zd30_api_menu`,
  article:`${basePath}/zd30_api_post/`,
  homeBanner:`${basePath}/zd30_api_banner`,
  signIn:`${basePath}/zd30_api_signin`,
  signUp:`${basePath}/zd30_api_signup`,
  headerFooter:`${basePath}/zd30_api_header_and_footer`,
  searchKeyword:`${basePath}/zd30_api_hot_search_keywords`,
  userProfile:`${basePath}/zd30_api_profile`,
  myResponse:`${basePath}/zd30_api_user_public_profile/`,
  mySubmit:`${basePath}/zd30_api_contactus/`,
  lyrics:`https://www.ziondaily.com/api/get_zionnewsong_lyrics.php?json_array=Y&id=`,
  weather:`https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=tc`,
  viewCount:`${basePath}/zd30_api_increase_view_count/home`,
  feedingBgMusic:`https://www.ziondaily.com/3.0/web/witnesses_of_god_03d/bgm.mp3`,
}

export const idMapTopic = {
  'pmt':{
    id:'pmt',
    topic:'最新文章',
    topPath:'/news/pmt/最新文章',
  },
  'blo':{
    id:'blo',
    topic:'Blog',
    topPath:'/news/blo/Blog',
  },
  'mus':{
    id:'mus',
    topic:'音樂特輯',
    topPath:'/news/mus/音樂特輯',
  },
  'btm':{
    id:'btm',
    topic:'BTTM',
    topPath:'/news/btm/BTTM',
  },
  'vid':{
    id:'vid',
    topic:'影片',
    topPath:'/news/vid/影片',
  },
  'river':{
    id:'river',
    topic:'栽在溪水旁',
    topPath:'/topics/02a/栽在溪水旁',
  },
};
