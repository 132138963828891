import { useEffect, useState } from "react";
import Image from 'next/image';
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from '../styles/Header.module.css';
import Link from 'next/link';
import TopicMenu from "./TopicMenu";
import { commonUrl } from "../pages/api/config";
import * as LIB from '../lib/common';
import * as cookieHelper from '../lib/cookieHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/fontawesome-free-regular';

const Header = ({ getTopicData }) => {
  const [zdMember, setIsMember] = useState(false);
  const [showMenu, setShowMenu] = useState(false); //topic menu control
  const [topicMenuData, setMenuData] = useState([]);
  const [headerData, setHeaderData] = useState([]);
  const [userIcon, setUserIcon] = useState('');
  const searchIcon = <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />;
  const smSearchIcon = <FontAwesomeIcon icon={faSearch} />;
  const loginIcon = <FontAwesomeIcon icon={faUserCircle} />;
  const [fontSize, setFontSize] = useState(16);

  useEffect(async () => {
    getHeaderData();
    getTopicMenuData();

    if (typeof window !== "undefined") {
      initFontSize();

      if (await cookieHelper.refreshSessionData()) {
        getUserData();
      }

      document.addEventListener("signin", async (evt) => {
        if (await cookieHelper.refreshSessionData()) {
          getUserData();
        }
      });
    }

  }, []);

  const initFontSize = () => {
    if (typeof window !== "undefined") {
      let checkFontSize = localStorage.getItem('fontSize');
      if(checkFontSize != null && checkFontSize != ''){
        if(checkFontSize == 'big'){
          setFontSize(20);
        }
      }
    }
  }

  const getTopicMenuData = async () => {
    //prepare top menu data
    const res = await fetch(commonUrl.menu)
    const resData = await res.json()
    setMenuData(resData);

    if (getTopicData) {
      generateTopicList(resData);
    }
  }

  const generateTopicList = (resData) => {
    if (resData) {
      let items = [];
      for (const cat in resData) {
        let subcats = resData[cat];
        for (const topicName in subcats) {
          let topicID = subcats[topicName].id;
          items[topicID] = topicName;
        }
      }

      getTopicData(items);
      //return items;
    }
  }

  const getUserData = async () => {
    //get the cookie from browser
    let cookies = cookieHelper.getCookie();
    if (cookies) {
      // console.log('cookies=', cookies);
      if (cookies.zd_mid && cookies.zd_avatar) {
        setIsMember(true);
        setUserIcon(cookies.zd_avatar);
      }
    }
  }

  const getHeaderData = async () => {
    //prepare top menu data
    const res = await fetch(commonUrl.headerFooter)
    const resData = await res.json()
    //console.log('header data=', resData);
    setHeaderData(resData.header);
  }

  const onToggleScrolling = () => {
    document.body.style.overflow = document.body.style.overflow == "hidden" ? "inherit" : "hidden";
  }

  const disableScrolling = () => {
      //hide the scrollbar
      document.body.style.overflow = "hidden";
  }

  const clickMoreBtn = () => {
    disableScrolling();
    setShowMenu(true);
  }

  const renderNavBtn = () => {
    let rows = [];
    for (let rs = 0; rs < headerData.length; rs++) {
      let navBtn = headerData[rs];
      let hasLink = (navBtn.link != '');
      let hasSubItems = (navBtn.sub_items.length > 0);

      if (hasLink && !hasSubItems) {
        //normal btn
        rows.push(
          <Nav.Link href={navBtn.link} key={rs}>
            <div className={styles.inherit} style={{fontSize:fontSize}}>{navBtn.title}</div>
          </Nav.Link>
        );
      }
      else if (hasSubItems) {
        //dropdown btn
        let subNavBtn = renderSubNavBtn(navBtn, rs);
        rows.push(subNavBtn);
      }
    }

    return rows;
  }

  const renderSubNavBtn = (navBtn, rs) => {
    //create an array to hold two values
    let row = [];
    for (let r = 0; r < navBtn.sub_items.length; r++) {
      const subNavBtn = navBtn.sub_items[r];
      row.push(<NavDropdown.Item href={subNavBtn.link} key={`sub${r}`} style={{fontSize:fontSize}}>{subNavBtn.title}</NavDropdown.Item>);

      //add divider except the last item
      if (r < navBtn.sub_items.length - 1) {
        row.push(<NavDropdown.Divider key={`div${r}`} />);
      }
    }

    //add the holder
    row = <NavDropdown id='navBtn' title={navBtn.title} className={styles.inherit} key={`dd${rs}`} style={{fontSize:fontSize}}>{row}</NavDropdown>
    return row;
  }

  const renderSmHeader = () => {
    //for Mobile or iPad
    let menuItems = topicMenuData;
    let menuRows = [];
    let count = 0; // unique element key for each menuRow

    if (topicMenuData) {
      for (const catTitle in menuItems) {
        let topicItems = menuItems[catTitle];
        let submenuRows = []; // hold subcat row

        //construct subcat
        for (const topicTitle in topicItems) {
          let topicItem = topicItems[topicTitle];

          // If url provided by API, use it directly, otherwise compose general URL for the corresponding topic ID
          let url = (topicItem.url != null && topicItem.url != '') ? topicItem.url : LIB.getBasepath(`/topics/${topicItem.id}/${topicTitle}`);

          submenuRows.push(
            <a href={url} key={topicItem.id}>
              <div className={styles.itemBtn}>{topicTitle}</div>
            </a>
          );
        }

        //construct cat & subcat section
        menuRows.push(
          <Col xl={3} md={4} s={6} xs={6} key={count}>
            <div className={styles.floatLeft}>
              <div className={styles.title}>{catTitle}</div>
              {submenuRows}
            </div>
          </Col>
        );

        count++;
      }

      return (
        <div className={styles.smfullWidth}>
          <div className="viewport">
            <div className={styles.smSubHeader}>
              <div className={styles.smLoginHolder}>
                {
                  zdMember && userIcon ?
                    <>
                      <a href={LIB.getBasepath('/userprofile')}>
                        <img src={userIcon} className={styles.userAvatar} alt="user" />
                      </a>
                      <a href={LIB.getBasepath('/userprofile')}>
                        <div className={styles.smLoginBtn}>我的帳戶</div>
                      </a>
                    </> :
                    <>
                      <a href={LIB.getBasepath('/signin')} className={styles.smATag}>
                        <div className={styles.smLoginIcon}>{loginIcon}</div>
                        <div className={styles.smLoginBtn}>登入帳戶</div>
                      </a>
                    </>
                } 
              </div>

              <a href={LIB.getBasepath('/search')} className={styles.smATag}>
                <div className={styles.smSearchHoder}>
                  <div className={styles.smSearchIcon}>{smSearchIcon}</div>
                  <div className={styles.smSearchBtn}>搜尋</div>
                </div>
              </a>
            </div>

            <Container fluid className={styles.innerHolder}>
              <Row>
                <Col xs={12}>
                  <div className={styles.smDivider}></div>
                </Col>
                {menuRows}
              </Row>
            </Container>
          </div>
        </div>
      );
    }
  }

  //main render for header
  if (headerData.length > 0) {
    return (
      <div className={`${styles.fullWidth} ${styles.navShadow}`}>
        <Navbar expand="lg" className={`viewport ${styles.navBar}`}>
          
          <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={onToggleScrolling} className={styles.toggleBtn} />
          
          <Navbar.Brand href={LIB.getBasepath('/')} className={`${styles.brandNav}`}>
            <div>
              <Image src={LIB.getBasepath('/images/home/zd3.png')} className={styles.brand} height={30} width={73} alt="website icon" />
            </div>
          </Navbar.Brand>
          
          <Navbar.Brand href={LIB.getBasepath('/search')} className={`d-lg-none ${styles.brandNav}`}>
            <div style={{width:73,color:'#fff',fontSize:20,marginTop:-5}}>{smSearchIcon}</div>
          </Navbar.Brand>
          
          <Navbar.Collapse id="responsive-navbar-nav" className={styles.navCollapse}>
            <Nav className="mr-auto"></Nav>
            <Nav className={`${styles.smNav}`}>
              {renderSmHeader()}
            </Nav>
            <Nav className={`container-fluid ${styles.lgNav}`}>
              {renderNavBtn()}
              <Nav.Link>
                <div onClick={clickMoreBtn} className={styles.inherit} style={{fontSize:fontSize}}>更多</div>
              </Nav.Link>

              <a href={LIB.getBasepath('/search')} className={styles.searchicon}>
                {searchIcon}
              </a>

              {
                zdMember && userIcon &&
                <a href={LIB.getBasepath('/userprofile')}>
                  <img src={userIcon} className={styles.userAvatar} alt="user" />
                </a>
              }

              {
                !zdMember && <Nav.Link>
                  <Link href='/signin'><div className={styles.signin} style={{fontSize:fontSize}}>登入</div></Link>
                </Nav.Link>
              }
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {showMenu && <TopicMenu data={topicMenuData} setMenu={setShowMenu} />}
      </div>
    );
  } else {
    return <></>
  }

};

export default Header;
