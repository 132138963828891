export const getCookie = () => {
  if(! document.cookie) return
  let allCookies = document.cookie.split(';');    
  let result = [];
  for (var i = 0; i < allCookies.length; i++) {
    let cookie = allCookies[i].split('=');
    let cookieName = decodeURIComponent(cookie[0].trim());
    let cookieValue = decodeURIComponent(cookie[1].trim());
    result[cookieName] = cookieValue;
  }
  return result;
}

export const refreshSessionData = async () => {
  const phpApi = process.env.phpApiUrl;
  const res = await fetch(`${phpApi}/reload_session.php`);
  //console.log('session data=', res.status);

  if (res.status == 200) {
    return true;
  }

  return false;
}